<template>
  <a-typography-title>New New Chat</a-typography-title>

  <div class="page-header min-vh-100"
    style="background-image: url('https://s2.loli.net/2023/01/12/iGrTkStAJoXmusW.jpg');">
    <span class="mask bg-primary opacity-6"></span>



    <!-- Main page -->

    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-12">
          <div class="card  max-height-vh-70 overflow-auto overflow-x-hidden mb-5 mb-lg-0">
            <!-- Search Friend -->
            <div class="card-header p-3">
              <a-typography-text style="font-size: 25px; color: rgba(255,255,255,0.3);">Contact</a-typography-text>
              <p class="">{{ username }}</p>
              <a-input-group compact style="margin-top: 10px;">
                <a-input v-model:value="searchTerm" style="width: calc(100% - 200px)"
                  placeholder="Search your friend with username" />
                <a-button type="primary" @click="searchUsers()">Search</a-button>
                <a-button v-if="isFriend" type="primary" @click="addFriend(searchTerm)">Add</a-button>
              </a-input-group>
            </div>


            <div class="card-body p-2">
              <a href="javascript:;" class="d-block p-2 border-radius-lg bg-gradient-primary">
                <div class="d-flex p-2">
                  <div class="ms-3">
                    <div class="justify-content-between align-items-center">

                      <!-- Friend List -->
                      <div class="contact-scroller-container">
                        <div class="contact-scroller">

                        </div>
                      </div>

                      <ul>
                        <li v-for="friend in friends">
                          <div class="info">
                            <p class="name" @click="openChat(friend)">{{ friend }}</p>
                            <!--                        <button @click="verifyFriend(verify_number)" class="button">Delect</button>-->
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-8 col-md-7 col-12">
          <div class="card blur shadow-blur max-height-vh-70">
            <div class="card-header shadow-lg">
              <div class="row">
                <div class="col-lg-10 col-8">
                  <div class="d-flex align-items-center">
                    <div class="ms-3">
                      <h6 class="mb-0 d-block">{{ current_friend }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Conversation Area -->
            <a-card :bordered="false" class="midCard" ref="midCard">
              <!-- <div class="moreRecords">
                <a-button @click="getMoreMsg">查看更多消息</a-button>
              </div> -->
              <div :key="index" v-for="(item, index) in messagelist">
                <a-form-item :class="item.sendername != username ? 'leftPart' : 'rightPart'">
                  <div :class="item.sendername != username ? '' : 'time'">
                    <span>{{ item.time }}</span>
                  </div>
                  <div class="chatDiv">
                    <span>{{ item.message }}</span>

                  </div>
                </a-form-item>
              </div>
            </a-card>
            <div class="d-flex">

              <div>

                <input class="form-control" type="text" id="wid" v-model="newMessage" v-on:keyup.enter="sendMsg"
                  placeholder="Enter a message" />

              </div>

              <button type="button" class="btn btn-primary" v-on:click="sendMsg">Send</button>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>


</template>
  
<script>

import "./argon-dashboard.css";
import axios from 'axios';
import CryptoJS from "crypto-js";
import JSEncrypt from 'jsencrypt';
import { RSAKey, KEYUTIL, KJUR, hextob64, b64tohex } from 'jsrsasign'



function aesEncrypt(key, content) {

  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESencrypt start");

  if (typeof content === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      content = JSON.stringify(content);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }

  const dataHex = CryptoJS.enc.Utf8.parse(content);

  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.ciphertext.toString();
}



// 传入一个哈希值当作aes密钥，计算一个解密后的gpt密钥。
function aesDecrypt(key, encrypted) {

  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESdecrypt start");

  const encryptedHexStr = CryptoJS.enc.Hex.parse(encrypted);
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

  return decryptedStr.toString();
}


// RSA decrypt
function msgDecrypt(privateKey, encrypted) {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  const decrypted = decrypt.decrypt(encrypted);

  console.log("decrypt", decrypted);
  return decrypted;
}


// RSA encrypt for messages
// msgEncrypt(public key, data to be encrypted)
// return: string | encrypted message
function msgEncrypt(publicKey, plaintext) {
  console.log("plain text", plaintext);
  console.log("public key", publicKey);
  // encrypto
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encrypted = encrypt.encrypt(plaintext);

  console.log("encrypted==> ", encrypted);
  return encrypted;
}

// verify(message need to be verified, received signature, sender's public key to be verified)
// return: {
// .verify: boolean | true: real sign, false: fake sign
// .msg: string | message
// }

function verify(data, publicKey) {
  console.log("verify data", data);

  // split the message
  let signature = data.split('.')[0]
  let msg64 = data.split('.')[1]

  // verify the signature
  let signatureVf = new KJUR.crypto.Signature({ alg: "SHA1withRSA", prvkeypem: publicKey });
  signatureVf.updateString(msg64);

  // 验签入参是16进制字符串，注意转码
  let verify = signatureVf.verify(b64tohex(signature));
  console.log(`公钥验签：${verify}`);
  let msg = atob(msg64);

  if (verify) {
    console.log(`公钥信息：${msg}`);
    return msg
  } else {
    return null
  }

}

//  sign(message needs to be signed , privateKey to sign)
//  return : string | signature encrypted with base64
function sign(msg, privateKey) {
  console.log("sign data", msg);
  let msg64 = btoa(msg)
  let signature = new KJUR.crypto.Signature({ alg: "SHA1withRSA", prvkeypem: privateKey });
  signature.updateString(msg64);

  // 签名返回结果是16进制字符串，注意转码
  let a = signature.sign();
  let sign = hextob64(a);
  console.log(`私钥签名：${sign}`);

  return sign + "." + msg64;
}


export default {
  mounted() {
    this.accessToken();
    this.connect();
    this.get_chat_list();
  },

  name: "main",
  data() {
    return {
      // 当前user的用户mim
      username: '',
      isFriend: false,
      friends: [],
      searchTerm: '',
      users: '',
      verify: false,
      verify_number: '',
      // 接受者的名字
      current_friend: '',
      demo: [],
      // chat 显示所有message
      messagelist: [],
      socket: '',
      new_message_received: '', // Message received from the socket
      // 用户的
      privateKey: localStorage.getItem('privateKey'),
      // 接收消息的public key
      publicFriendKey: "",
      // 用户的public key
      publicKey: "",
      messages: [],  // Json array [{username: "user1", message: "hello"}, {username: "user2", message: "hi""}]
      newMessage: '', // Message in the input box
      token: '', // Token of the user
      server: '',
      chatList: []// 储存聊天列表

    }
  },
  // update() {
  //   let div = document.getElementById("chatBox");
  //   div.scrollTop
  // },
  methods: {
    accessToken() {

      // const access_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2M2MzNTM5N2NmMzZkYmI0YTIyNThjODUiLCJpYXQiOjE2NzM4MzA2ODYsIm5iZiI6MTY3MzgzMDY4NiwianRpIjoiYzIwZWI5MzMtODQ0MS00NmI3LWI0ZTgtYzc0OGIxNzg3YjNiIiwiZXhwIjoxNjczODczODg2LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.JnqqvUzXCUeqIAZ3mY1pZ_o2SVxEysMpb1AZEryaIG7Mo_ozaR2Xd87bsJiVMm767ojScLoV1hEwNb4PvoUP_9SNXgUjeo4BgEAZfAN2ft0jwN8zR5RoiNmtp3KS4q9BI0gqfgmFCKJdV3kb922iS5ZODsXVX-EPACHZrfTH0f3vrr14GRT6bmW7KckJLPVSEL9sM2dPSXAD3DYyGA2l-A8Eo1Ha_R66NyeLCmNIbVATSj_ligStp3jSmKDSnCZZtYIHeFGR46pAQiMqzKu7bQZQZHq6CGjfArp2IcxkNm-J_P4sYz67bjvwHfiTq9Bn0e2Qi58s0h2YJVwNbdEPFw";
      // localStorage.setItem('access_token', access_token);
      // this.getFriends();
      // this.getPublicKey();

      axios.get('https://cwb.365.day/user/token', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
        }
      }).then(response => {

        console.log(response.data);
        localStorage.setItem('access_token', response.data.access_token);

        if (response.data.status == true) {
          this.getFriends();
        }

      }).catch(error => {
        console.log(error);
        // 加入回到登录界面的代码
        alert(error['response']['data']['detail']);
      });



      this.username = localStorage.getItem('username');

      axios.post("https://cwb.365.day/user/get_public_key", { "username": this.username }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')

        }
      }).then(response => {
        console.log(response.data);

        if (response.data.status == true) {

          this.publicKey = response.data.public_key;

        }

      }).catch(error => {

        console.log(error);
        alert(error['response']['data']['detail']);

      });


    },

    connect() {
      this.socket = new WebSocket("wss://cwb.365.day/ws?token=" + localStorage.getItem('access_token'));
      this.socket.onopen = (event) => {
        console.log("Socket is connected to server");
      }

      this.socket.onclose = (event) => {
        console.log("Socket is closed");
      }

      this.socket.onerror = (event) => {
        console.log("Socket error");
      }

      // this.get_chat_list();
      //接收到新消息，更新当前消息， 更新消息列表



      this.socket.onmessage = (event) => {
        console.log("Received a message from the server! Message is : " + event.data);
        let localtime = new Date().toLocaleString();
        if (event.data.startsWith("receive")) {
          console.log("Received a message from the server! Message is : " + event.data);
          // payload list : sender name message
          let payloadList = event.data.split(" ");
          console.log("payloadList", payloadList);

          const sendername = payloadList[1];
          const encryptedMessage = payloadList[2];

          //  this.socket.send("send " + data.targetname + " " + data.message);

          // const user_public_key = this.get_public_key(this.username);
          const decryptedMessage = this.demulti(localStorage.getItem(this.current_friend), encryptedMessage);

          console.log("decryptedMessage pbk ==>", localStorage.getItem(this.current_frient))


          const data = {
            sendername: sendername,
            message: decryptedMessage,
            time: localtime,
            sent: true,
            read: true,
          }

          this.messagelist.push(data);

        }
        if (event.data.startsWith("send failed")) {
          console.log("send failed");
        }

      }
    },


    // Tested by pinyi
    getFriends() {
      axios.get('https://cwb.365.day/user/friend', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {
        this.friends = response.data.friends;
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      })

    },

    //
    get_chat_history(sendername, targetname) {
      var body = {
        username: targetname
      };

      axios.post("https://cwb.365.day/chat/history", body, {

        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {

        const state = response.data.status;
        const messages = response.data.messages;

        console.log("messages==>", messages);
        // 当前用户的 private key
        const privateKey = localStorage.getItem('privateKey');

        let localtime = new Date().toLocaleString();

        const results = [];



        for (const message of messages) {
          console.log("message sender", message.send);
          console.log("MESSAGE SENDER: THIS>USERNAME");
          console.log(message.send);
          if (message.send == this.username) {
            console.log("USERNAME ====> ", this.username);
            this.get_public_key(this.username);
            // const public_key = localStorage.getItem(this.username)

            const public_key = localStorage.getItem(this.username)
            // if (verify(message.message,this.publicKey)) {
            const payload = message.message;
            console.log("demulti public_key", public_key)
            console.log("demulti payload", payload)
            const messageReceived = this.demulti(public_key, payload);
            console.log("messageReceived", messageReceived);
            // const decodeMessage = RSAdecrypt(messageReceived[1], privateKey);

            let result = {
              sendername: message.send,
              message: messageReceived,
              time: localtime,
              sent: message.sent
            };

            results.push(result);

            // }
          } else {

            // if (verify(this.otherEncrypt,message.signature, this.publicFriendKey)) {
            console.log("message debug", message);
            const payload = message.message;
            this.get_public_key(this.current_friend);
            const publicFriendKey = localStorage.getItem(this.current_friend);
            const messageReceived = this.demulti(publicFriendKey, payload);


            let result = {
              sendername: message.send,
              message: messageReceived,
              time: localtime,
              sent: message.sent
            };

            results.push(result);

            // }
          }
        }

        this.messagelist = results;
        console.log("messagelist==>", this.messagelist);
        // const chats = response.data.messages;
      }
      );

    },

    openChat(friend) {

      // 创建实例时设置配置的默认值
      this.current_friend = friend;

      const body = {
        "sendername": this.username,
        "targetname": this.current_friend
      };
      axios.post("https://cwb.365.day/user/get_public_key", { "username": this.current_friend }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
          // 'Authorization': 'Bearer ' + access_token
        }
      }).then(response => {
        this.get_chat_history(this.username, friend);
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      });
      console.log(body);

    },

    // Tested by pinyi
    searchUsers() {
      // 实际应该使用异步获取用户的方法进行调用，比如axios或fetch
      const body = {
        "username": this.searchTerm
      };

      this.current_friend = this.searchTerm;
      this.openChat(this.current_friend);

      axios.post('https://cwb.365.day/user/find', body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {
        console.log(response.data);
        if (response.data.status == true) {
          this.isFriend = true;
          this.users = response.data.username;
        } else {
          this.isFriend = false;
          alert(error['response']['data']['detail']);
        }
      }).catch(error => {
        this.isFriend = false;
        console.log(error);
        alert(error['response']['data']['detail']);
      })

    },

    // Tested by pinyi
    addFriend(user) {

      const body = {
        "username": user

      };
      // 实际应该使用异步添加好友的方法进行调用，比如axios或fetch
      axios.patch('https://cwb.365.day/user/friend', body, {

        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')

        }
      }).then(response => {
        console.log(response.data)
        if (response.data.status == true) {
          this.isFriend = false;
          this.users = '';
          this.searchTerm = '';
          axios.get('https://cwb.365.day/user/friend', {

            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
          }).then(response => {
            this.friends = response.data.friends;
          }).catch(error => {
            console.log(error);
            alert(error['response']['data']['detail']);
          })
        } else {
          this.isFriend = false;
          this.users = '';
          this.searchTerm = '';
        }
      }).catch(error => {
        console.log(error);
        this.isFriend = false;
        this.users = '';
        this.searchTerm = '';
        alert(error['response']['data']['detail']);
      })
    },




    // 101 行
    sendMsg() {
      if (this.newMessage != "") {
        const username = localStorage.getItem('username');
        let localtime = new Date().toLocaleString();
        const current_time = new Date();
        console.log("current_friend: 0" + this.current_friend);

        this.get_public_key(this.current_friend);
        const get_key_friend = localStorage.getItem(this.current_friend);
        console.log("get_key: ", get_key_friend);

        this.get_public_key(this.username);
        const get_key_username = localStorage.getItem(this.username);

        const body = [{
          username: this.current_friend,
          public_key: localStorage.getItem(this.current_friend)
        },
        {
          username: this.username,
          public_key: localStorage.getItem(this.username)
        }
        ];

        console.log("body's key", body[0].public_key);

        let content = this.multi(body, this.newMessage);
        console.log("NEW MESSAGE:======>", this.newMessage);
        const data = {
          'sendername': username,
          'targetname': this.current_friend,
          'time': current_time.getTime(),
          // 我自己 加密消息  ｜  朋友 加密 ｜ 我的签名
          'message': content,
          'sent': true
        };

        const msg = {
          sendername: this.username,
          message: this.newMessage,
          time: localtime,
          sent: true,
          read: true,
        }

        // username , pubkickey {friendList, Friendpublick}

        // socket.emit("send " + data.targetname + " " + data.message + " " + data.signature);
        this.socket.send("send " + data.targetname + " " + data.message);
        this.messages.push(data);
        this.messagelist.push(msg);
        console.log("messagelist: ", this.messagelist);
        this.newMessage = "";
        this.get_chat_list();
      }

    },

    generatePassword(number) {
      let possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=";
      let password = "";
      for (let i = 0; i < number; i++) {
        password += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
      }
      return password;
    },

    get_chat_list() {
      axios.get("https://cwb.365.day/chat/unread", {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {
        console.log("get chatlist ", response);
        console.log("chatlist", this.chatList);
        for (let message of response.data.messages) {
          if (!this.chatList.includes(message.send) && !this.chatList.includes(message.receive)) {
            if (message.send != this.username) {
              console.log("message sendername", message.send);
              this.chatList.push(message.send);
            } else {
              console.log("message sendername", message.receive);
              this.chatList.push(message.receive);
            }
          }

        }
        console.log("chatlist", this.chatList);
        console.info(this.chatList);
        // return response.data.public_key;
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      });




    },



    // multi(users who can read the message, msg)
    // users [["username",this.current_friend,"publicKey",this.publicFriendKey]]
    // return: message string
    multi(users, msg) {
      const key = this.generatePassword(16);
      let result = '';
      for (let user of users) {
        console.log("friend public key==>", user.public_key);
        // window.
        result = result + btoa(user.username) + "." + msgEncrypt(user.public_key, key) + "|";
      }
      const privateKey = localStorage.getItem('privateKey');
      console.log("privateKey==>", this.privateKey)
      result = result + "|" + aesEncrypt(key, sign(msg, this.privateKey))
      console.log("multi result==>", result)
      return result;
    },

    // demulti (sender, msg) return (username = this.username)string
    demulti(public_key, msgs) {
      for (let user of msgs.split('||')[0].split('|')) {
        console.log("user==>", user);
        const data = user.split(".");
        console.log("username:", atob(data[0]))
        if (atob(data[0]) === localStorage.getItem("username")) {

          console.log("data[1]==>", data[1]);
          console.log("msgDecrypt(this.privateKey, data[1]):", msgDecrypt(this.privateKey, data[1]))
          console.log("msgs.split('||')[1]:", msgs.split('||')[1])

          const decryptMSG = aesDecrypt(msgDecrypt(this.privateKey, data[1]), msgs.split('||')[1]);
          console.log("decryptMSG==>", decryptMSG);
          return verify(decryptMSG, public_key)
        }
      }
      return null;
    },
    get_public_key(user) {
      axios.post("https://cwb.365.day/user/get_public_key", { "username": user }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {
        console.log("get public key ", response);
        localStorage.setItem(user, response.data.public_key);
        // return response.data.public_key;
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      });
      // return " ";
    }

  }
}

</script>


<style lang = "less">
.contact-scroller-container {
  width: auto;
  height: 100%;
  overflow: hidden;
}

.contact-scroller {
  overflow-y: scroll;
  width: calc(100%-10px);
  height: 100%;
}

.chat-window {
  height: 1000px;
  overflow: hidden;
}

#button:hover {
  border-radius: 8px;
  background-color: #008CBA;
}

.right {
  float: right;
}

.left {
  float: left;
}

#hid {
  height: 500px;
}

#wid {
  width: 500px;
  position: center;
}


.topCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.midCard {
  height: 400px;
  // 透明背景
  background-color: rgba(255, 255, 255, 0.2);
  overflow-y: auto;

  .leftPart {
    width: 100%;

    .chatDiv {
      padding: 0 10px 0 10px;
      float: left;
      // CSS3内容自适应
      width: fit-content;
      background-color: white;
      border-radius: 5px;
    }
  }

  .rightPart {
    width: 100%;

    .chatDiv {
      padding: 0 10px 0 10px;
      float: right;
      width: fit-content;
      background-color: #98e165;
      border-radius: 5px;
    }
  }
}

.bottomCard {
  height: 150px;
}

// div {
//   border: 1px solid black;
// }
.time {
  display: flex;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0);
}

.moreRecords {
  display: flex;
  justify-content: center;

  .ant-btn {
    border: 0;
    background-color: rgba(255, 255, 255, 0);
    color: cornflowerblue;
  }
}

::v-deep .ant-table-thead {
  display: none;
}

.unRead {
  color: red;
  left: -1px;
  font-size: 30px;
  line-height: 5px;
  position: absolute;
}

.img {
  width: 100px;
  height: 100px;
}

.largeImg {
  width: 600px;
  height: 600px;
}

::v-deep .ant-modal-body {
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 1 !important;
}
</style>
