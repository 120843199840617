
<template>
  <div class="page-header min-vh-100"
    style="background-image: url('https://s2.loli.net/2023/01/12/iGrTkStAJoXmusW.jpg');">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">

      <div class="card border-0 mb-0">
        <div class="card-header bg-transparent">
          <div class="btn-wrapper text-center">
            <div>
              <div v-if="showLogin">
                <h2 class="text-dark text-center mt-2 mb-3">Login</h2>
                <form @submit.prevent="login">
                  <div class="text-center">
                    <label for="password">Username:</label>
                    <input type="username" id="username" v-model="username" />
                  </div>
                  <br />
                  <div class="text-center">
                    <label for="password">Password: </label>
                    <input type="password" id="password" v-model="password" />
                  </div>
                  <br />
                  <div class="text-center">
                    <label for="password">Secret Password: </label>
                    <input type="password" id="password" v-model="secret_password" />
                  </div>
                  <br />
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary">Login</button>
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      or
                    </p>
                  </div>
                  <button @click="showLogin = !showLogin" class="btn bg-gradient-dark">Register</button>
                </form>
              </div>
              <div v-else>
                <h2>Register</h2>
                <form @submit.prevent="register">
                  <div class="text-center">
                    <label for="password">Username:</label>
                    <input type="username" id="username" v-model="username" />
                  </div>
                  <br />
                  <div class="text-center">
                    <label for="password">Password:</label>
                    <input type="password" id="password" v-model="password" />
                  </div>
                  <br />
                  <div class="text-center">
                    <label for="password">Confirm Password:</label>
                    <input type="password" id="confirm password" placeholder="Must be same as above"
                      v-model="confirm_password" />
                  </div>
                  <br />
                  <div class="text-center">
                    <label for="password">Secret Password:</label>
                    <input type="password" id="Secret password" placeholder="Must be different from the previous"
                      v-model="secret_password" />
                  </div>
                  <br />
                  <button type="submit" class="btn btn-primary">Register</button>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      or
                    </p>
                  </div>
                  <button @click="showLogin = !showLogin" class="btn bg-gradient-dark">Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import "./argon-dashboard.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';
const { Buffer } = require('buffer');
import JSEncrypt from 'jsencrypt';


//  genereate RSA key pair
function genKeyPair() {
  const encrypt = new JSEncrypt();

  encrypt.getKey();

  // Get the private key as a string
  const privateKey = encrypt.getPrivateKey();

  // Get the public key as a string
  const publicKey = encrypt.getPublicKey();

  console.log("Private Key: ", privateKey);
  console.log("Public Key: ", publicKey);

  return { privateKey: privateKey, publicKey: publicKey };

}

function getAESkey(privateKey, iteration_number) {
  let result = privateKey;

  for (let i = 1; i < iteration_number; i++) {
    result = CryptoJS.SHA256(result);
  }

  console.log('result==>', result.toString());

  return result.toString();
}

function AESencrypt(key, privateKey) {

  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESencrypt start");

  if (typeof privateKey === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      privateKey = JSON.stringify(privateKey);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }

  const dataHex = CryptoJS.enc.Utf8.parse(privateKey);

  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.ciphertext.toString();
}



// 传入一个哈希值当作aes密钥，计算一个解密后的gpt密钥。
function AESdecrypt(key, encrypted) {
  console.log("crypted==>", encrypted);
  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESdecrypt start");

  const encryptedHexStr = CryptoJS.enc.Hex.parse(encrypted);
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

  return decryptedStr.toString();
}





export default {

  name: "Login",
  data() {
    return {
      "username": '',
      "password": '',
      "secret_password": '',
      "confirm_password": '',
      "showLogin": true,

    }

  },
  methods: {
    login() {

      const body = {
        username: this.username,
        password: this.password
      };

      axios.post('https://cwb.365.day/user/token', body)
        .then(response => {
          // Handle the response from the server

          const private_key = response.data.secret_key;

          const AESKey = getAESkey(this.secret_password, 1000);
          console.log("AESKey==>", AESKey);
          const secret_key = AESdecrypt(AESKey, private_key);
          // const secret_key = AESdecrypt(private_key,AESKey);
          console.log("secret_key==>", secret_key);

          console.log("refersh_token", response.data.refresh_token);

          localStorage.setItem('refresh_token', response.data.refresh_token);
          localStorage.setItem('username', this.username);
          localStorage.setItem('privateKey', secret_key);


          this.$router.push({ path: '/main' });
        })
    },

    async register() {
      const key = await genKeyPair();

      const privateKey = key.privateKey;
      const publicKey = key.publicKey;

      const AESkey = getAESkey(this.secret_password, 1000);

      const secret_key = AESencrypt(AESkey, privateKey);
      // const secret_key = AESencrypt(privateKey, AESkey);

      const body = {
        username: this.username,
        password: this.password,
        secret_key: secret_key,
        public_key: publicKey,
        confirm_password: this.confirm_password

      };

      axios.post('https://cwb.365.day/user', body).then(response => {

        localStorage.setItem('refresh_token', response.data.refresh_token);
        this.showLogin = true;

        console.log(response.data);
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      })
    }
  },
};
</script>

<style>
input::placeholder {
  color: rgb(231, 147, 147);
  font-size: 12px;
}
</style>


