<template>
  <div class="text-center">
    <p>
      <button type="submit" class="btn btn-primary" @click="testSign">testSign</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="testVerify">testVerify</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="testMulti">testMulti</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="testDemulti">testDemulti</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="getFriends">getFriends</button>
    </p>
    <p>
      <a-input v-model="searchTerm" style="width: 200px" placeholder="Username" />
      <button type="submit" class="btn btn-primary" @click="searchUsers">searchUser</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="addFriend">addFriend</button>
    </p>
    <p>
      <button type="submit" class="btn btn-primary" @click="openChat">openChat</button>
    </p>
  </div>
</template>

<script>

// Online Javascript Editor for free
// import {privateKey} from "@/userAction/KeyEncrypt";

// const crypto = require('crypto');\
// const encrypt = require('jsencrypt');

// }

// import "./argon-dashboard.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';
const { Buffer } = require('buffer');
import JSEncrypt from 'jsencrypt';
import { RSAKey, KEYUTIL, KJUR ,hextob64,b64tohex} from 'jsrsasign'

const access_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2M2M0NGRlNzIxN2NmMTExNjQ1MjA1OTkiLCJpYXQiOjE2NzM4MDk0ODQsIm5iZiI6MTY3MzgwOTQ4NCwianRpIjoiMjFjMjRlMWQtMWQwNC00NTQwLWIzMWMtZjlhNGFmYTRmNzZiIiwiZXhwIjoxNjczODUyNjg0LCJ0eXBlIjoiYWNjZXNzIiwiZnJlc2giOmZhbHNlfQ.EE8ZPwAyBJ2ycX5PnBo2B_QCZ0honjWXUfMa2Okj0FRhaPKV69H30l97soUqzve4AdjwlHEcbIBhibTYGZwq1ZqDwtXqHxryIoBAKSHjKTBaHhYLcqlIK27s9D0JFsYb3_b_goXLYTax-KqbY2VIFKnYDz7HbZkY32xmC3HjAWkrHu9HHmcAN7Rp_qHERkypfaC1gOS5zMMB8iFJMpoiDcXCzvBfTv-nSSPIaZT-tEdUVpyNmy1UqZdh3kJsm12Ox-2EB_oLJBoiIxtZJ9sRv6aodCYe79KIIVfWzOo1G9MgX5JvUtODWqLgVaseEtZmBggtxp5K_XIFpDnOYpCBXw";

const public_key = "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBnmhiHvR78kJgtKlCQVt2T\n" +
    "0W+bruBqFu4udIi2mJQIis74s/joQdaIglijKeBvU+uNoaNzmRnaTtgKp5VV3CFl\n" +
    "Tk6N+uoyNEgrhBXlnb6E2VI3IVzpbrB997oLnwPlEZrsvDUzcVdEJgiBPhD6L7l0\n" +
    "/6us/Hlxa+QlnbwrZYBkrD/rQej2HsAS7jCDZ15Cqvik+VElll8egq721k2NrB6O\n" +
    "7tiHCRJLR8AaKJ40eIFee37SNBYb3cMQkjadVfJrqljKTvUd+D3nyVoYqcgr3ybr\n" +
    "tfslgXXiy0EDVrzUwXcYGndIRrhGVY+5JYq/QPnzeznxZtIT80KRRdRKHJEyZd7P\n" +
    "AgMBAAE=\n" +
    "-----END PUBLIC KEY-----";

const private_key = "-----BEGIN RSA PRIVATE KEY-----\n" +
    "MIIEogIBAAKCAQBnmhiHvR78kJgtKlCQVt2T0W+bruBqFu4udIi2mJQIis74s/jo\n" +
    "QdaIglijKeBvU+uNoaNzmRnaTtgKp5VV3CFlTk6N+uoyNEgrhBXlnb6E2VI3IVzp\n" +
    "brB997oLnwPlEZrsvDUzcVdEJgiBPhD6L7l0/6us/Hlxa+QlnbwrZYBkrD/rQej2\n" +
    "HsAS7jCDZ15Cqvik+VElll8egq721k2NrB6O7tiHCRJLR8AaKJ40eIFee37SNBYb\n" +
    "3cMQkjadVfJrqljKTvUd+D3nyVoYqcgr3ybrtfslgXXiy0EDVrzUwXcYGndIRrhG\n" +
    "VY+5JYq/QPnzeznxZtIT80KRRdRKHJEyZd7PAgMBAAECggEAHnZa3B6pq83AURdu\n" +
    "FOLVyA7+6CHlboxLgHFXeYknjdNyxAH3ICOrtvvMoD1B9roQOtbaPrU3us8QCyH3\n" +
    "cQWG9EiwJGMw1Y414kW2Qou9Z0aXPAqdTS30yzU6CQy6knI4wX8djYC4Dhu4yHe9\n" +
    "ejLqLxK2IekitEzrCnx0xbXUqMTzflp3pBKOZ4NqkyU0fOkdaHOvJQu7Y3yrPd2c\n" +
    "oGCxhTeKB/OkROcDDmOCCWcL1K2k7RQLByPPCZRLp+N2uQe0q5sBIxgSMggSuLsq\n" +
    "rItdZ1CHmxoKHYV5EE3jXgZb3XmIzY3k1zDiDVTeFEnvKaw08mK6WDl/Ac88/t58\n" +
    "wHMUkQKBgQDFjKW9jM5fFsehrWzX44/fHcgZQE9RxDEB9jwYAtIei0eF3Q0WJYOh\n" +
    "JXHPzVlNWWA546MD0mTodS/bIrYlrKcoQLFcOEmAa61oXFp4K13NxjLck/q0rMcw\n" +
    "7q2ENZwil+Hb6YBsMRhj8hl8uQDs24joQr2niqb28jRdUekW6QgJuwKBgQCGQWkM\n" +
    "ouook4w2e9tWObo41UQxzSrGsAGBSbYMOfttHG3cx9OYjoOHwVITEtrL0R3MdHBj\n" +
    "oMTyjfLhcQSEjKgxKGaq6QOqrAmZ4pkvN/uEUDzAF1YrQcPjlEoop4TZl1hGQLA2\n" +
    "YzmYGYi6ZNan8pI8BR+NLWUsyHXw5ZvtuEwz/QKBgCdu7d6qbr94cp0JW0K1ABEP\n" +
    "htPTjIHL4eR8XH6GgZ7rOnm0KFqVXnMaYF+UpBDWmGi10eJ/avkj+0uI4Ecg8bUN\n" +
    "RNoduj5SQCkm1kBKl8ztlVWGjHLoUlKoMibD9MjK/KDDOYlfO3kn6IKHA3JEWE3U\n" +
    "lLsO6Ek/C8jdAuQ53ArlAoGAJX8fV+gofiy3fIAbQnndCQKHiWFEEToXMYTk04Ve\n" +
    "M5s9E+y7rGAhXEkiIzGg6kv9hpz+xtSgLR54N37ZRCwEzbX69sEdxQ1HoTMolYeN\n" +
    "N+cxEHV0G6DClU1VaQVglWUvwm9Ox+HutAxto6GGUmHtddX4efWzzE4wopd+dWaD\n" +
    "4gECgYEAocsWR9WJ/N01q0a5KTVdFt1EC0Zlobo66LPFX0nbuPDlU9tIxuws3jm+\n" +
    "acjFKd3JpaXaYhJsUgK+qMICeIQ234g2fvqWZ6jybzX5v1T7AChncUM/sP5gJyTU\n" +
    "4uqtsOPJqeI4gghXcApa9xP7f8O2Hsl4T7UbET6ei+uDRuEEueE=\n" +
    "-----END RSA PRIVATE KEY-----";

const public_key_2 = "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkFACY+GX8xnu1lGlPtCW\n" +
    "Hn7FXK1NoRyt2sUdZGOGbXcGENGin/jXgWtmaDI5M2c9bGITnbxb09r90aWoiLyj\n" +
    "0yzkhJfo8q1319sSySMxSDLfxZcukeWMvVZmeqVEehuPUnpJ+Bq2KArPZs0bvkfz\n" +
    "1QwqkK8nLmz0zSJHVBDitxkYlroIpup1Xp0jdwzx5QeGaxxapHh0f9ZTBI4GhwMp\n" +
    "giopGgIeR+S9Ou1Nw8EvgOqULU+fY1IBI+99ZXWAb5i3h25nItPwEz7Xv7bjTL7n\n" +
    "GdxXfBM0OF006la5XsTtFIdSzV1GXV6x8/iIpqLTYlJr0DbkGPv3ypRq3mXM7J1H\n" +
    "hQIDAQAB\n" +
    "-----END PUBLIC KEY-----"

const private_key_2 = "-----BEGIN RSA PRIVATE KEY-----\n" +
    "MIIEpQIBAAKCAQEAkFACY+GX8xnu1lGlPtCWHn7FXK1NoRyt2sUdZGOGbXcGENGi\n" +
    "n/jXgWtmaDI5M2c9bGITnbxb09r90aWoiLyj0yzkhJfo8q1319sSySMxSDLfxZcu\n" +
    "keWMvVZmeqVEehuPUnpJ+Bq2KArPZs0bvkfz1QwqkK8nLmz0zSJHVBDitxkYlroI\n" +
    "pup1Xp0jdwzx5QeGaxxapHh0f9ZTBI4GhwMpgiopGgIeR+S9Ou1Nw8EvgOqULU+f\n" +
    "Y1IBI+99ZXWAb5i3h25nItPwEz7Xv7bjTL7nGdxXfBM0OF006la5XsTtFIdSzV1G\n" +
    "XV6x8/iIpqLTYlJr0DbkGPv3ypRq3mXM7J1HhQIDAQABAoIBAQCBPEWHsQVknXq3\n" +
    "z5IOOLnXQWv5XPHCMG65FgEGX+KIsShrc3ERlcVYjxe8hA2ZrQDiyMoMXhxWEy1u\n" +
    "y82r9lM30GItI+VnPlWupu+h1LRTlcuzpXn9CfcJsBBCETLLr1agZAdN+o3aOiQW\n" +
    "SeRoeQMxbZ3IyDJsbwKjmekKrbx6fsjjFHB8XiIK9I8BcDpDybri1eRoTVd32/EH\n" +
    "+hcGymQ+MvG0o+Wxspkrj9+ETZyhAPbBzgv8tBrTz1QtdHarANen/8fimmFOsNSa\n" +
    "puE+f7PVzHOLDcELV1yzQwt080pS65K8VAKXTo5R6pEEJVj4C2qLGsIYlJDG5yYs\n" +
    "U9c5/p5ZAoGBAPmdlD+QK073CHyDzVmuzvWqZD3ywmVeOfQXSAyINSYhxg+IOFNx\n" +
    "iUxeFrD/piP6zTZZ7K+vzRNqwBSOs2Dwp6bIr9dbQ54vUqUHXLYNoJ/PZAn0geET\n" +
    "Ptzm9kTT3vQ/R3a7GCfDb/AbktnZUF2P5Hj014bzzzd2L3Ha1mCr37kDAoGBAJQA\n" +
    "7qKxHtJ6FMvX2az5cTcCMlg3QZjrJFhHmD8RCj8dU3JRjEMLXJsnqqPDAp9+jY4/\n" +
    "immWQlv74MO2GjB1D0pe8SkC79Ha6Q1J3hb+rh12YmH/bVR3WyYF6GUnX7l9EdfU\n" +
    "/92wwgF3R6s7HA3CJjFcVuw1sd7fv6/VC3xrN6LXAoGBAMRBL9jV+icItSRo4GB9\n" +
    "v3yJbm0CW/MlJX2TfHNV+Cu17Co0GM0KfD+Nzlt1dOPfAc+U3jG3kA6PJNxE4iX6\n" +
    "sGXCbfZta/mIDXtxEWSVzE852Na25hLXddpqwP+WcToqMn7YVQKWd4EZvNkJMlWN\n" +
    "l72+C2KweKCLjHwA6gJxv+GPAoGAKK5w6atBy5ze5oD4RkFySyqBaBoYH85SeSmE\n" +
    "waEPbfXDoCKmKS+QTt2CW+0YZXrFshnpE8jHiL0dNO5zGGq0vz6Fjz5NaSOA97Qc\n" +
    "JbnvZg3KUSGCjbsZBMSO8m72Mugb5XWYtvkE+p4sxvZCac257ctx4Q+JSre52rz2\n" +
    "oUZfTpUCgYEA6V8+caFknsX8UO8D7gEM0xKz/76KgzDPbttxw8Cd5UKj40fbC0lC\n" +
    "DwzQi7KBFiE8bhfC9Px8fUWhdN0SZOGtzXwgOHmn5IZ0KVxJ/L1bE2n3mY+jo4vZ\n" +
    "ha1A3/AnDmNBrLsu8Scfzv7XGz4jk6aGpiJcP7jDSEjl8ijgjmWzPVs=\n" +
    "-----END RSA PRIVATE KEY-----"
// verify(message need to be verified, received signature, sender's public key to be verified)
// return: {
// .verify: boolean | true: real sign, false: fake sign
// .msg: string | message
// }


function aesEncrypt(key, content) {

  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESencrypt start");

  if (typeof content === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      content = JSON.stringify(content);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }

  const dataHex = CryptoJS.enc.Utf8.parse(content);

  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.ciphertext.toString();
}



// 传入一个哈希值当作aes密钥，计算一个解密后的gpt密钥。
function aesDecrypt(key, encrypted) {

  const SECRET_KEY = CryptoJS.enc.Utf8.parse(key);
  const SECRET_IV = CryptoJS.enc.Utf8.parse(key);

  console.log("AESdecrypt start");

  const encryptedHexStr = CryptoJS.enc.Hex.parse(encrypted);
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

  return decryptedStr.toString();
}



function verify(data, publicKey) {
  console.log("verify data",data);

  // split the message
  let signature = data.split('.')[0]
  let msg64 = data.split('.')[1]

  // verify the signature
  let signatureVf = new KJUR.crypto.Signature({alg:"SHA1withRSA",prvkeypem:publicKey});
  signatureVf.updateString(msg64);

  // 验签入参是16进制字符串，注意转码
  let verify = signatureVf.verify(b64tohex(signature));
  console.log(`公钥验签：${ verify}`);
  let msg = atob(msg64);

  if (verify) {
    return msg
  } else {
    return null
  }

}

//  sign(message needs to be signed , privateKey to sign)
//  return : string | signature encrypted with base64
function sign(msg,privateKey) {
  console.log("sign data",msg);
  let msg64 = btoa(msg)
  let signature = new KJUR.crypto.Signature({alg:"SHA1withRSA",prvkeypem:privateKey});
  signature.updateString(msg64);

  // 签名返回结果是16进制字符串，注意转码
  let a = signature.sign();
  let sign = hextob64(a);
  console.log(`私钥签名：${sign}`);

  return sign + "." + msg64;
}

function generatePassword(number) {
  let possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=";
  let password = "";
  for (let i = 0; i < number; i++) {
    password += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
  }
  return password;
}


// multi(users who can read the message, msg)
// users [["username",this.current_friend,"publicKey",this.publicFriendKey]]
// return: message string
function multi (users, msg) {
  const key = generatePassword(16);
  let result = '';
  for (let user of users){
    result = result + hextob64(user.username) + "." + msgEncrypt(user.public_key, key) + "|";
  }
  result = result + "|" + aesEncrypt(key, sign(msg, private_key))

  return result;
}

// demulti (public key of sender, msg) return (username = this.username)string
function demulti (public_key, msgs) {
  for (let user of msgs.split('||')[0].split('|')) {
    const data = user.split(".")
    // if (b64tohex(data[0]) === localStorage.getItem("username")) {
    if (b64tohex(data[0]) === "a2") {
      const decryptMSG = aesDecrypt(msgDecrypt(private_key_2, data[1]),msgs.split('||')[1])
      return verify(decryptMSG, public_key)
    }
  }
  return null;
}

// RSA decrypt
function msgDecrypt(privateKey, encrypted) {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  const decrypted = decrypt.decrypt(encrypted);

  console.log("decrypt=>", decrypted);
  return decrypted;
}


// RSA encrypt for messages
// msgEncrypt(public key, data to be encrypted)
// return: string | encrypted message
function msgEncrypt(publicKey, plaintext) {
  console.log("encrypt data",plaintext);

  // encrypto
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encrypted = encrypt.encrypt(plaintext);

  console.log("encrypted==> ", encrypted);
  return encrypted;
}

export default {
  name: "test",
  data() {
    return {
      username: '',
      isFriend: false,
      friends: [],
      searchTerm: '',
      users: '',
      verify: false,
      verify_number: '',
      current_friend: '',
      demo: [],
      messagelist: [],
      socket: '',
      new_message_received: '', // Message received from the socket
      privateKey: localStorage.getItem('privateKey'),
      publicFriendKey: "",
      publicKey: "",
      messages: [],  // Json array [{username: "user1", message: "hello"}, {username: "user2", message: "hi""}]
      newMessage: '', // Message in the input box
      token: '', // Token of the user
      server: '',
    }
  },

  methods:{

    getFriends() {

      axios.get('http://31.205.219.97:8000/user/friend', {
        headers: {
          'Authorization': 'Bearer ' + access_token
        }
      }).then(response => {
        console.log("response.data==>", response);
        this.friends = response.data.friends;
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      })

    },

    searchUsers() {
      // 实际应该使用异步获取用户的方法进行调用，比如axios或fetch
      const body = {
        "username": this.searchTerm
      };

      axios.post('http://31.205.219.97:8000/user/find', body, {
        headers: {
          'Authorization': 'Bearer ' + access_token
        }
      }).then(response => {
        console.log(response.data);
        if (response.data.status == true) {
          this.isFriend = true;
          this.users = response.data.username;
        } else {
          // this.isFriend = false;
          // alert(error['response']['data']['detail']);
        }
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      })
    },

    addFriend() {
      const body = {
        "username": this.users
      };
      // 实际应该使用异步添加好友的方法进行调用，比如axios或fetch
      axios.patch('http://31.205.219.97:8000/user/friend', body, {

        headers: {
          'Authorization': 'Bearer ' + access_token

        }
      }).then(response => {
        console.log(response.data)
        if (response.data.status == true) {
          this.isFriend = false;
          this.searchTerm = '';
          axios.get('http://31.205.219.97:8000/user/friend', {

            headers: {
              'Authorization': 'Bearer ' + access_token
            }
          }).then(response => {
            this.friends = response.data.friends;
          }).catch(error => {
            console.log(error);
            alert(error['response']['data']['detail']);
          })
        } else {
          this.isFriend = false;
          this.searchTerm = '';
        }
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      })
    },

    get_chat_history() {
      let sendername = "huihuihui"
      let targetname = "唐娟3"
      var body = {
        username: targetname
      };

      axios.post("http://31.205.219.97:8000/chat/history", body, {

        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
      }).then(response => {

            const state = response.data.status;
            const messages = response.data.messages;

            console.log("messages==>", messages);
            // 当前用户的 private key
            const privateKey = localStorage.getItem('privateKey');

            let localtime = new Date().toLocaleString();

            const results = [];

            // "messages":[{"_id":{"$oid":"63c3707668cdd2d69de2f183"},
            // "send":"a1",
            // "receive":"a2",
            // "time":1673752694.6250763,
            // "message":"asdfasdf",
            // "sent":false,
            // "read":false,
            // "users":["a1","a2"]}
            // message

            // 'message': username + " " + this.myEncrypt + " " + this.current_friend + " " + this.otherEncrypt +" " +signatureMyself,
            for (const message of messages) {
              if (message.sendername == this.username) {

                if (verify(message.message,this.publicKey)) {

                  const messageReceived = message.message.split(" ");
                  const decodeMessage = RSAdecrypt(messageReceived[1], privateKey);

                  let result = {
                    sendername: message.sendername,
                    message: decodeMessage,
                    time: localtime,
                    sent: message.sent
                  };

                  result.push(result);

                  console.log("demo==>", this.demo);

                }
              } else {

                if (verify(this.otherEncrypt,message.signature, this.publicFriendKey)) {

                  const messageReceived = message.message.split(" ");
                  const decodeMessage = RSAdecrypt(messageReceived[2], privateKey);

                  let result = {
                    sendername: message.sendername,
                    message: decodeMessage,
                    time: localtime,
                    sent: message.sent
                  };

                  results.push(result);

                  console.log("demo==>", this.demo);

                }
              }
            }

            this.messagelist = results;

            console.log("demo==>", this.demo);
            // const chats = response.data.messages;
          }
      );

    },

    openChat(friend) {

      // 创建实例时设置配置的默认值
      this.current_friend = "唐娟3";

      const body = {
        "sendername": "huihuihui",
        "targetname": this.current_friend
      };
      axios.post("http://31.205.219.97:8000/user/get_public_key", { "username": this.current_friend }, {
        headers: {
          'Authorization': 'Bearer ' + access_token

        }
      }).then(response => {
        console.log(response.data);

        if (response.data.status == true) {
          this.publicFriendKey = response.data.public_key;
          this.get_chat_history(this.username, friend);
        }
      }).catch(error => {
        console.log(error);
        alert(error['response']['data']['detail']);
      });

      console.log(body);
    },

    testSign() {
      let msg = "hello world";
      let result = sign(msg, private_key);
      console.log(result);
    },

    testVerify() {
      let data = sign(msg, private_key);
      let msg = verify(data, public_key);
      console.log(msg);
    },

    testMulti() {
      let users = [{
        username: "a1",
        public_key: "-----BEGIN PUBLIC KEY-----\n" +
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDa7QFanqf4q4ER1nrTqco++Z7w\n" +
            "TlIPGvMLwITckUhigJyCcO2pb1T9ZO9991UHy+KSQmm9WsSxD0FngkRSGEk4aCKb\n" +
            "wc591PxBcnqzh4kpn7RPdDvmhpoNDQJ4KnJtHC+ULCE6gRC3FPLcrQCCJw/Kv0zH\n" +
            "oGottInTYkumvt6pdQIDAQAB\n" +
            "-----END PUBLIC KEY-----"
      },
        {
          username: "a2",
          public_key: "-----BEGIN PUBLIC KEY-----\n" +
              "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGugxZeGLRjEyyQdc/SnpK3mg2q9\n" +
              "vQRlko0SvWERIlF15lL76d5aS998uN+4Idp5Bxij/3dYk642V2wqtP5izheGq2gX\n" +
              "JK+bpVLw/Eq7K4I0iN5+GygGLxaQ7e6gIfYRTH7xVG2ZfGO0HRT/10rzUM0EvMUi\n" +
              "wo01TQKJR5SVDxcFAgMBAAE=\n" +
              "-----END PUBLIC KEY-----"
        }
      ];
      let msg = "hello world2";
      console.log(multi(users, msg));
      multi(users, msg);
    },

    testDemulti() {
      let users = [{
        username: "a1",
        public_key: public_key
      },
        {
          username: "a2",
          public_key: public_key_2
        }
      ];
      let msg = "hello world3";
      // localStorage.setItem("username","huihuihui")
      console.log("decryptMSG:",demulti(public_key,multi(users, msg)))
    }



}
}

</script>

<style scoped>

</style>