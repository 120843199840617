import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '../views/mainWindow.vue'
import Login from '../views/Login.vue'
import Test from '../views/test.vue'
// @ts-ignore
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
